/* Font Import */
@import url("https://fonts.googleapis.com/css2?family=Island+Moments&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Island+Moments&family=Work+Sans:wght@300;400;700&display=swap");

h1 {
  font-family: Island Moments;
}

* {
  font-family: Work sans;
}
