/* general */

:root {
  --white: #fafafa;
  --black: #0d0d0f;
}

.slick-next {
  right: 62px;
  z-index: 10;
}

.slick-prev {
  left: 62px;
  z-index: 10;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
}

/* home page image */
@media (min-width: 768px) {
  .home-img-wrapper::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 50vw;
    height: 80vh;
    background: linear-gradient(
      90deg,
      rgba(7, 7, 7, 1) 0%,
      rgba(0, 0, 0, 0) 67%
    );
  }

  /* about page image */
  .about-img-wrapper::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 50vw;
    height: 80vh;
    background: linear-gradient(270deg, #020f1f 0%, rgba(0, 0, 0, 0) 100%);
  }

  /* priority page image */
  .priority-img-wrapper::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 50vw;
    height: 70vh;
    background: linear-gradient(270deg, #020f1f 0%, rgba(0, 0, 0, 0) 100%);
  }

  /* about page image */
  .aboutPage-img-wrapper::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 40vw;
    height: 85vh;
    background: linear-gradient(90deg, rgb(7, 7, 7) 0%, rgba(0, 0, 0, 0) 80%);
  }
}
