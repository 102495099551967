@media (min-width: 768px) {
  .sticky-section {
    display: flex;
    min-height: 100vh;
  }

  .sticky-section .left,
  .sticky-section .right {
    flex: 1;
  }

  .sticky-section .left .inner {
    height: 100vh;
    display: block;
  }

  .sticky-section .right .inner {
    padding: 40px 75px;
  }

  .sticky-section .left .inner.sticky {
    position: sticky;
    top: 0;
  }

  .sticky-section .left .inner.sticky .bg {
    display: block !important;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
}

.sticky-section .left .inner.sticky .bg {
  display: none;
}
